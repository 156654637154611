import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapActions, mapState, mapGetters } from 'vuex'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import { getElasticProduct } from 'src/modules/prismic/store/actions'

export default {
  created() {
    this.isEcommerce = this.$store.state['zenith-ui'].ecommerce
    const getUrlKey = (name, sku) => {
      return name && sku ? name.toLowerCase().replace(/ /g, '-') + '-' + sku.replace(/\./g, '-').replace(/\//g, '-') : ''
    }
    const key = this.product.routeParam || this.product.url_key || getUrlKey(this.product.name, this.product.sku)
    if (!this.product.position) {
      getElasticProduct(key)
        .then((raw) => raw.product[this.storeCode])
        .then((raw) => {
          this.product.raw = raw
          // if (this.product.raw.is_product_sold_out) this.isProductSoldOut = this.product.raw.is_product_sold_out
        })
    }
  },
  data() {
    return {
      appeared: false,
      isEcommerce: true
    }
  },
  computed: {
    ...mapState('wishlist-extend', ['products']),
    fullProduct() {
      return this.product.raw || this.product
    },
    isPreorder() {
      const product = this.product.raw || this.product
      return product.is_preorder
    },
    isBoutiqueEdition() {
      const product = this.product.raw || this.product
      return product.boutique_edition
    },
    isLimitedEdition() {
      const product = this.product.raw || this.product
      return product.limited_edition
    },
    isSpecialEdition() {
      const product = this.product.raw || this.product
      return product.special_edition
    },
    isShopNow() {
      const product = this.product.raw || this.product
      return product.is_shoppable && !product.is_preorder && this.isEcommerce && !product.is_product_sold_out
    },
    isOnlyOnline() {
      const product = this.product.raw || this.product
      return product.only_available_online && this.isEcommerce
    },
    isProductSoldOut() {
      const product = this.product.raw || this.product
      return product.is_product_sold_out
    },
    isDiscover() {
      const product = this.product.raw || this.product
      return (!product.is_shoppable && !product.is_preorder) || !this.isEcommerce || product.is_product_sold_out
    },
    hideLabel() {
      const product = this.product.raw || this.product
      return product.hide_plp_label
    },
    isEditionType() {
      const product = this.product.raw || this.product
      return product.option_value_edition_type
    },
    accountActivated() {
      const { account } = currentStoreView()
      return account !== false
    },
    isInWishlist() {
      return this.products.find((item) => this.product.sku === item.sku)
    },
    localizedUrl() {
      return currentStoreView().url || ''
    },
    storeCode() {
      return currentStoreView().storeCode || 'en_gb'
    },
    discoverLabel() {
      let label = this.$t('Discover') + ' ' + this.product.name
      label += ' ' + (this.product.option_value_bracelet ? this.product.option_value_bracelet : this.product.raw ? this.product.raw.option_value_bracelet : '')
      label += ' ' + (this.product.option_value_material ? this.product.option_value_material : this.product.raw ? this.product.raw.option_value_material : '')
      if (this.product.price) {
        label += ' - ' + this.$options.filters.currency(this.product.price)
      }
      if (this.product.raw && this.product.raw.is_product_sold_out) {
        label += ' - ' + this.product.raw.is_product_sold_out
      }
      return label
    },
    picture() {
      return {
        url: this.imageUrl,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrl, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrl, 1000, 200, 100, 1.4285714286),
        alt: this.imageAlt,
        width: this.imageSizes.width,
        height: this.imageSizes.height
      }
    },
    pictureSizes() {
      return '(max-width: 768px) 29vw, (max-width: 992px) 26vw, 20vw'
    },
    pictureHover() {
      return {
        url: this.imageUrlHover,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrlHover, 70, 1.7238535137, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrlHover, 1000, 200, 100, 1.7238535137),
        alt: this.imageAlt,
        width: this.imageHoverSizes.width,
        height: this.imageHoverSizes.height
      }
    },
    pictureSwap() {
      return {
        url: this.imageUrlHover,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrlHover, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrlHover, 1000, 200, 100, 1.4285714286),
        alt: this.imageAlt,
        width: this.imageHoverSizes.width,
        height: this.imageHoverSizes.height
      }
    },
    pictureSwapHover() {
      return {
        url: this.imageUrl,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrl, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrl, 1000, 200, 100, 1.4285714286),
        alt: this.imageAlt,
        width: this.imageSizes.width,
        height: this.imageSizes.height
      }
    },
    imageUrl() {
      if (this.fullProduct.image) {
        return ImageManager.convert(this.fullProduct.image, 'image', 'product')
      } else if (this.fullProduct.images && this.fullProduct.images[0].src) {
        return ImageManager.convert(this.fullProduct.images[0].src, 'image', 'product')
      } else return null
    },
    imageUrlHover() {
      return this.fullProduct.imageHover ? ImageManager.convert(this.fullProduct.imageHover, 'image', 'product') : ''
    },
    imageSizes() {
      return {
        width: 320,
        height: 428
      }
    },
    imageHoverSizes() {
      return {
        width: 320,
        height: 428
      }
    },
    imageAlt() {
      if (this.fullProduct.image) {
        return this.fullProduct.name
      } else if (this.fullProduct.images && this.fullProduct.images[0].alt) {
        return this.fullProduct.images[0].alt
      } else return ''
    },
    sizingGuidePdf() {
      const { i18n } = currentStoreView()
      const { defaultLocale } = i18n
      const fileName = defaultLocale === 'en-ge' ? `sizing-guide-en-GE.pdf` : defaultLocale === 'fr-ge' ? `sizing-guide-fr-GE.pdf` : `sizing-guide-${defaultLocale}.pdf`
      const path = `/assets/pdf/${fileName}`
      return path
    },
    // check if swapImages is true and if imageHover is not null
    swapImages() {
      return this.fullProduct.swapImages && (this.fullProduct.imageHover != null && this.fullProduct.imageHover !== '')
    }
  },
  methods: {
    ...mapActions('cart', { addToCart: 'addItem' }),
    ...mapActions('wishlist-extend', ['addItem', 'removeItem', 'loadWishlist']),
    ...mapActions('feedback', ['show']),
    ...mapGetters('user', ['isLoggedIn']),
    strapBasePrice(product) {
      let strapOption = null
      let productLink = []
      if (product.bundle_options) {
        strapOption = product.bundle_options.filter(option => (option.title === 'Strap'))
      }
      if (strapOption && strapOption.length && strapOption[0].product_links) {
        productLink = strapOption[0].product_links.filter(option => option.sku.includes('Standard'))
        if (productLink.length === 0) {
          productLink = strapOption[0].product_links[0]
        }
      }
      return productLink.length > 0 ? productLink[0].price : 0
    },
    determineWishlist() {
      if (!this.isLoggedIn()) {
        this.show({
          message: 'You must be logged in to add a product to your wishlist',
          context: 'error'
        })
      } else if (this.isInWishlist) {
        return this.removeFromWishlist(this.product.sku)
      } else return this.addToWishlistAndFeedback(this.product.sku)
    },
    removeFromWishlist(sku) {
      this.removeItem(sku).then(() => {
        this.loadWishlist()
        /* this.show({
          message: 'Your product has been removed from your wishlist'
        }) */
        this.callGTM('removeFromWishlist')
      })
    },
    addToWishlistAndFeedback(sku) {
      this.addItem(sku).then(() => {
        this.loadWishlist()
        // this.show({ message: 'Your product has been added to your wishlist' })
        this.callGTM('addToWishlist')
      })
    },
    wishlistLabel(trad) {
      let label = trad + ' ' + this.product.name
      label += ' ' + (this.product.option_value_bracelet ? this.product.option_value_bracelet : this.product.raw ? this.product.raw.option_value_bracelet : '')
      label += ' ' + (this.product.option_value_material ? this.product.option_value_material : this.product.raw ? this.product.raw.option_value_material : '')
      return label
    },
    callGTM(event) {
      const call = () => {
        const data =
          event === 'addToWishList' || event === 'removeFromWishlist'
            ? {
              event: event,
              products: [
                {
                  name: this.product.raw ? this.product.raw.name : '',
                  id: this.product.raw ? this.product.raw.sku : '',
                  position: this.listPosition,
                  price: this.product.raw ? this.product.raw.price : '',
                  brand: 'Zenith',
                  category: 'Watches',
                  productCase: this.product.raw ? this.product.raw.option_value_material : '',
                  productCaseSize: this.product.raw ? this.product.raw.option_value_diameter : '',
                  productColor: this.product.raw ? this.product.raw.option_value_dial : '',
                  productMasterId: this.product.raw ? this.product.raw.collection : '',
                  productSubCategory: this.product.raw ? this.product.raw.option_value_line : '',
                  productMidCategory: this.product.raw ? this.product.raw.option_value_collection : '',
                  productPowerReserve: this.product.raw ? this.product.raw.option_value_power_reserve : '',
                  productStock: this.product.raw && this.product.raw.stock && this.product.raw.stock.is_in_stock ? 'In stock' : 'Out of stock',
                  productStrap: this.product.raw ? this.product.raw.option_value_bracelet : '',
                  productWaterResistance: this.product.raw ? this.product.raw.option_value_water_resistance : ''
                }
              ]
            }
            : {
              event: event,
              listName: this.listName || '',
              listType: this.listType || '',
              products: [
                {
                  name: this.product.raw ? this.product.raw.name : '',
                  id: this.product.raw ? this.product.raw.sku : '',
                  position: this.listPosition,
                  price: this.product.raw ? this.product.raw.price : '',
                  brand: 'Zenith',
                  category: 'Watches',
                  productCase: this.product.raw ? this.product.raw.option_value_material : '',
                  productCaseSize: this.product.raw ? this.product.raw.option_value_diameter : '',
                  productColor: this.product.raw ? this.product.raw.option_value_dial : '',
                  productMasterId: this.product.raw ? this.product.raw.collection : '',
                  productSubCategory: this.product.raw ? this.product.raw.option_value_line : '',
                  productMidCategory: this.product.raw ? this.product.raw.option_value_collection : '',
                  productPowerReserve: this.product.raw ? this.product.raw.option_value_power_reserve : '',
                  productStock: this.product.raw && this.product.raw.stock && this.product.raw.stock.is_in_stock ? 'In stock' : 'Out of stock',
                  productStrap: this.product.raw ? this.product.raw.option_value_bracelet : '',
                  productWaterResistance: this.product.raw ? this.product.raw.option_value_water_resistance : ''
                }
              ]
            }
        this.$gtm.trackEvent(data)
      }
      call()
    },
    generateCoordinate() {
      let coordinate = Math.floor(Math.random() * 20) + 5
      coordinate *= Math.floor(Math.random() * 2) === 1 ? 1 : -1
      return coordinate
    },
    enter() {
      this.$emit('enterInViewport', this.listPosition)
    },
    addToCartAndFeedback(product) {
      const payload = this.size
        ? {
          productToAdd: {
            ...product,
            product_option: {
              extension_attributes: { custom_options: [this.size] }
            }
          }
        }
        : { productToAdd: product }
      this.addToCart(payload).then(() => {
        /* this.show({
          message: 'Your product has been added to your shopping bag'
        }) */
      })
    }
  }
}
