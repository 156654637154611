<template>
  <Observer :class="classObs" rootMargin="100px" @enter="enter">
    <transition name="fade">
      <router-link v-if="isActivated" key="link" class="product" :dataSku="product.sku" :to="product.routeParam ? `${localizedUrl}/product/${product.routeParam}` : `${localizedUrl}/product/${product.url_key}`" :aria-label="discoverLabel" @click.native="callGTM('eeListClick')">
        <div class="headline">
          {{ product.headline }}
          <span>{{ product.raceDate }}</span>
        </div>
        <div class="visual -active">
          <lazy-image v-if="imageUrl" :class-name="'watch'" ref="picture" :item="picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
        </div>
        <h3 class="title" aria-hidden="true" v-if="product.name" v-text="product.name"></h3>
        <span class="price" aria-hidden="true" v-if="getProductPrice(product)">{{ getProductPrice(product) | currency }}</span>
        <div class="btn -small" aria-hidden="true">{{ $t('Discover') }}</div>
      </router-link>
      <div v-else class="product" key="soon">
        <div class="headline">
          {{ product.headline }}
          <span>{{ product.raceDate }}</span>
        </div>
        <div class="visual">
          <lazy-image v-if="imageWaitingUrl" :class-name="'watch'" ref="picture" :item="waitingPicture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
        </div>
        <h3 class="title" aria-hidden="true" v-if="product.name" v-text="product.name"></h3>
        <div class="btn -small -disabled" disabled aria-hidden="true">{{ $t('Coming soon') }}</div>
      </div>
    </transition>
  </Observer>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import ProductItemData from '../utilities/product-item-data'
import LazyImage from './utils/LazyImage'
import { chronomasterNow } from '../utilities/chronomaster-timing'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import { getProductPrice } from '../utilities'

export default {
  props: ['product', 'close', 'classObs', 'listPosition', 'listName', 'listType'],
  name: 'ExtremeEItem',
  mixins: [ReadyHook, ProductItemData],
  components: {
    LazyImage
  },
  data () {
    return {
      isActivated: false
    }
  },
  methods: {
    getProductPrice: getProductPrice
  },
  computed: {
    waitingPicture () {
      return {
        url: this.imageWaitingUrl,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageWaitingUrl, 70, 1.70940171, false, 4),
        srcset: ImageManager.getSrcSet(this.imageWaitingUrl, 1000, 200, 100, 1.70940171),
        alt: this.imageAlt,
        width: this.imageSizes.width,
        height: this.imageSizes.height
      }
    },
    imageWaitingUrl () {
      if (this.product.waitingImage && this.product.waitingImage.image && this.product.waitingImage.image.url) {
        return this.product.waitingImage.image.url
      } else {
        return null
      }
    },
    picture() {
      return {
        url: this.imageUrl,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrl, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrl, 1000, 200, 100, 1.4285714286),
        alt: this.imageAlt,
        width: this.imageSizes.width,
        height: this.imageSizes.height
      }
    },
  },
  mounted () {
    if (this.product.availableDate) {
      chronomasterNow()
        .then(response => {
          let limit = new Date(this.product.availableDate).getTime() - new Date(response * 1000).getTime()
          if (limit <= 0) {
            this.isActivated = true
          } else {
            this.isActivated = false
          }
        })
    } else {
      this.isActivated = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.obs {
  height: 100%;
}

.product {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &:hover, &:focus {
    .visual.-active .watch {
      /deep/ img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}

.headline {
  min-height: 5.5rem;
  margin-bottom: 1rem;
  font-size: em(12);
  line-height: em(18, 12);
  letter-spacing: em(1, 12);
  text-transform: uppercase;
  color: #262626;
  transition: all 0.2s ease-in-out;

  span {
    display: block;
    opacity: 0.4;
  }
}

.visual {
  position: relative;
  width: 100%;
  background: #DFDFDF;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out;

  &.-active {
    background: #262626;
    height: 0;
    padding-top: 170.940171%;
    overflow: hidden;

    .watch {
      /deep/ img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 85%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .watch {
    width: 100%;
    overflow: hidden;

    /deep/ img {
      width: 100%;
      position: relative;
      transition: all 0.2s ease-in-out;
    }
  }
}

.title {
  color: var(--zenith-theme-primary);
  font-size: em(20);
  line-height: em(30, 20);
  letter-spacing: em(0, 20);
  margin-bottom: 2rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;

  & + .price {
    margin-top: -1rem;
  }
}

.price {
  color: #7A7A7A;
  font-size: em(12);
  line-height: em(19, 12);
  letter-spacing: em(1, 12);
  text-transform: uppercase;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out;
}

.btn {
  margin-top: auto;
}

</style>
