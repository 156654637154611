<template>
  <Observer name="lazy-image" :className="[className, 'lazy-image']" @enter="enter" :rootMargin="rootMargin" @leave="leave">
    <img v-if="canBeRendered" ref="picture" :src="imageLowDefinition" :alt="imageAlt ? imageAlt : ' '" :srcset="srcSet" :sizes="sizes" :width="imageWidth ? imageWidth+'px' : false" :height="imageHeight ? imageHeight+'px' : false" :loading="getLoadingAttribute" :draggable="getDraggableAttribute">
    <noscript v-if="renderWhenAppeared">
      <img ref="picture" :src="imageLowDefinition" :alt="imageAlt ? imageAlt : ' '" :srcset="srcSet" :sizes="sizes" :width="imageWidth ? imageWidth+'px' : false" :height="imageHeight ? imageHeight+'px' : false" :loading="getLoadingAttribute"></img>
    </noscript>
  </Observer>
</template>

<script>
export default {
  props: ['item', 'sizes', 'rootMargin', 'className', 'emptyAlt', 'itemprop', 'render-when-appeared', 'loading', 'draggable'],
  name: 'lazy-image',
  data () {
    return {
      appeared: false,
    }
  },
  watch: {
    item: function (newVal, oldVal) {
      this.$refs.picture.srcset = this.item.srcset
    }
  },
  computed: {
    imageUrl() {
      return this.item.url
    },
    srcSet() {
      return this.item.srcset
    },
    imageLowDefinition() {
      return this.item.url.indexOf('.gif') > -1 ? '/assets/img/spacer.gif' : this.item.lowDefinitionUrl
    },
    imageAlt() {
      return this.emptyAlt ? '' : this.item.alt
    },
    imageWidth() {
      return this.item.width ? this.item.width : null
    },
    imageHeight() {
      return this.item.height ? this.item.height : null
    },
    canBeRendered() {
      if (!this.renderWhenAppeared) {
        return true
      }

      return this.appeared
    },
    getLoadingAttribute() {
      if (this.loading) {
        return this.loading
      }
      return 'lazy'
    },
    getDraggableAttribute() {
      if (this.draggable) {
        return this.draggable
      }
      return true
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.appeared = true
      }
    },
    leave() {
    }
  },
  metaInfo () {
    return this.itemprop ? {
      meta: [
        { vmid: 'image', name: 'image', content: this.item.url + '?im=Resize=(400,400)' }
      ]
    } : {}
  }
}
</script>
