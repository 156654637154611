<template>
  <section ref="section" class="-visibility-auto cta-title-text">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <Observer name="cta-enter" @enter="enter">
            <h3 v-if="item.headline" v-text="item.headline" class="subtitle -upper"></h3>
            <h2 v-text="item.title" class="h3 title -upper"></h2>
            <router-link class="btn -stroke -large" v-text="item.ctaText" :title="item.ctaText" v-if="item.ctaText" :to="localizedUrl + item.ctaLink" @click.native="callGTM('eePromoClick', item)"></router-link>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import { getRouteName } from '../../utilities'

export default {
  name: 'HRCtaTitleText',
  props: ['item', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  data() {
    return {
      appeared: false
    }
  },
  computed: {
    localizedUrl() {
      const { url } = currentStoreView()
      return url || ''
    },
  },
  methods: {
    callGTM(event, item) {
      if (getRouteName(this.$route.name) === 'home') {
        let data = {
          event: event,
          nextPageVisited: this.localizedUrl + item.ctaLink,
          promotions: [{
            creative: 'section_text_home',
            id: 'section_text_home_' + this.pagePosition,
            name: (item.subtitle ? item.title + ' - ' + item.subtitle : item.title) || 'Section text home',
            position: this.index + 1
          }]
        }
        this.$gtm.trackEvent(data)
      }
    },
    enter() {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    }
  },
  created() {},
  mounted() {},
  destroyed() {}
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.-icons .title {
  display: inline;
  font-size: em(24);
  font-weight: normal;
  color: var(--zenith-white) !important;
  background: var(--zenith-theme-primary) !important;
  box-shadow: -2px 0px 0px 6px var(--zenith-theme-primary);
}

section {
  margin: 10rem 0;
  position: relative;
  @include bp(max md) {
    margin: 4rem 0;
  }
}

.obs-cta-enter {
    .subtitle {
      opacity: 0;
    }
    .title {
      opacity: 0;
      transform: translateY(6rem);
    }

    a, button {
      transform: translateY(4rem);
      opacity: 0;
    }
}

.title {
  margin-bottom: 4rem;
  color: var(--zenith-theme-primary);

  opacity: 1;
  transform: translateY(0);
  @include appearTitle(100ms);
}

.subtitle {
  font-size: em(16);
  font-weight: 500;
  letter-spacing: em(2, 16);
  margin-bottom: .4rem;
  text-transform: uppercase;

  opacity: 1;
  @include appearTitle();
}

button, a {
  @include appear(400ms);
}
</style>
