<template>
  <div>
    <section ref="section" :class="{'-visibility-auto': pagePosition}">
      <div v-if="pagePosition === 0">
        <video v-if="item.video" ref="video" autoplay playsinline loop muted>
          <source v-if="isMobile && item.videoMobile && item.videoMobile.url" ref="source" :src="item.videoMobile.url + '?impolicy=' + item.impolicy" type="video/mp4">
          <source v-else ref="source" :src="item.video.url + '?impolicy=' + item.impolicy" type="video/mp4">
        </video>
        <lazy-picture v-else-if="imageUrl" :item="item" preserve-ratio="true" />
        <div class="title-container text-center" :class="textCssClasses" v-if="item.headline || item.title || item.ctaLink">
          <h3 class="headline" v-if="item.headline" v-text="item.headline"></h3>
          <h2 class="title" v-if="item.title" v-html="item.title"></h2>
          <router-link :to="localizedUrl + item.ctaLink" class="btn -stroke -large" :class="textCssClasses" v-text="item.ctaText" v-if="item.ctaLink && !item.ctaLink.includes('http') && item.ctaText"></router-link>
          <a :href="item.ctaLink" class="btn -stroke -large" :class="textCssClasses" v-text="item.ctaText" v-if="item.ctaLink && item.ctaLink.includes('http') && item.ctaText"></a>
        </div>
      </div>
      <Observer name="fade-up" v-else @enter="enter" @leave="leave">
        <video v-if="item.video" ref="video" autoplay playsinline loop muted>
          <source v-if="isMobile && item.videoMobile && item.videoMobile.url" ref="source" :data-src="item.videoMobile.url + '?impolicy=' + item.impolicy" type="video/mp4">
          <source v-else ref="source" :data-src="item.video.url + '?impolicy=' + item.impolicy" type="video/mp4">
        </video>
        <lazy-picture v-else :item="item" preserve-ratio="true"></lazy-picture>
        <div class="title-container text-center" :class="textCssClasses" v-if="item.headline || item.title || item.ctaLink">
          <h3 class="headline" v-if="item.headline" v-text="item.headline"></h3>
          <h2 class="title" v-if="item.title" v-html="item.title"></h2>
          <router-link :to="localizedUrl + item.ctaLink" class="btn -stroke -large" :class="textCssClasses" v-text="item.ctaText" v-if="item.ctaLink && !item.ctaLink.includes('http') && item.ctaText"></router-link>
          <a :href="item.ctaLink" class="btn -stroke -large" :class="textCssClasses" v-text="item.ctaText" v-if="item.ctaLink && item.ctaLink.includes('http') && item.ctaText"></a>
        </div>
      </Observer>
      <button v-if="item.video" type="button" class="btn-autoPlay" :class="{'-paused': pauseVideo}" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo"></button>
      <button v-if="item.products && item.products.length > 0" type="button" @click="onOpenModal" class="btn-preview">
        <i class="icon-cart"></i>
        <span>{{ $t('Watch preview') }}</span>
      </button>
    </section>
    <ModalProduct :is-open="openModalProduct" :data="{image: item.image, products: item.products}" @close="onCloseModal" />
  </div>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'AdaptiveImage',
  props: ['item', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  components: {
    LazyPicture,
    ModalProduct: () => import('./ModalProduct')
  },
  created() {},
  mounted() {},
  data() {
    return {
      pauseVideo: false,
      appeared: false,
      openModalProduct: false
    }
  },
  computed: {
    textCssClasses() {
      return {
        '-white': !this.isMobile && this.item.textColor === 'white',
        '-white-mobile': this.isMobile && this.item.textColorMobile === 'white'
      }
    },
    localizedUrl () {
      return currentStoreView().url || ''
    },
    isMobile() {
      return isServer ? true : this.$store.state['zenith-ui'].menu.mobile
    },
    imageUrl() {
      return (this.item.image && this.item.image.url) ? this.item.image.url : null
    },
    imageMobileUrl() {
      return (this.item.imageMobile && this.item.imageMobile.url) ? this.item.imageMobile.url : null
    },
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.imageUrl, 200, 1, true)
    },
    imageSrcSetMobile () {
      return ImageManager.getSrcSet(this.imageMobileUrl, 1600, 200, 100, 1, true)
    },
    imageSrcSet () {
      return ImageManager.getSrcSet(this.imageUrl, 2000, 200, 100, 1, true)
    },
    desktopAlt() {
      if (this.item.main_picture && this.item.main_picture.alt) {
        return this.item.main_picture.alt
      } else if (this.item.title) {
        return this.item.title
      } else return ''
    }
  },
  methods: {
    enter() {
      if (!this.appeared && this.$refs.video) {
        this.appeared = true
        this.$refs.section.classList.remove('-visibility-auto')
        if (this.$refs.source.dataset.src) {
          this.$refs.source.setAttribute('src', this.$refs.source.dataset.src)
          this.$refs.source.removeAttribute('data-src')
          setTimeout(() => {
            this.$refs.video.load()
          }, 200)
        }
      } else if (this.$refs.video && this.$refs.video.paused) {
        this.$refs.video.play()
        this.pauseVideo = false
      }
    },
    leave() {
      if (this.$refs.video && !this.$refs.video.paused) {
        this.$refs.video.pause()
        this.pauseVideo = true
      }
    },
    toggleVideo () {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    },
    onOpenModal () {
      this.openModalProduct = true
    },
    onCloseModal () {
      this.openModalProduct = false
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';
@import '~theme/css/elements/watch-preview';
section {
  position: relative;

  .btn-autoPlay {
    position: absolute;
  }
}

/deep/ img,
/deep/ video {
  width: 100%;
}

.title-container {
  position: absolute;
  bottom: 8rem;
  width: 100%;

  @include bp(max md) {
    bottom: 4rem;
  }

  .headline {
    display: block;
    font-size: em(16);
    font-weight: normal;
    line-height: 1;
    letter-spacing: em(0.2, 16);
    margin-bottom: 1.3rem;
    color: var(--zenith-theme-primary);

    @include bp(max md) {
      font-size: em(12);
      line-height: em(18, 12);
      letter-spacing: em(2, 12);
    }
  }

  .title {
    font-size: em(38);
    font-weight: normal;
    line-height: 1;
    letter-spacing: em(0.2, 38);
    margin-bottom: 6rem;
    color: var(--zenith-theme-primary);

    @include bp(max md) {
      font-size: em(30);
      line-height: em(40, 30);
      margin-bottom: 2rem;
    }
  }

  &.-white {
    .headline {
      color: var(--zenith-white);
    }
    .title {
      color: var(--zenith-white);
    }
  }

  &.-white-mobile {
    @include bp(max md) {
      .headline {
        color: var(--zenith-white);
      }
      .title {
        color: var(--zenith-white);
      }
    }
  }
}

</style>
